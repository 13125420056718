// Headlines

@mixin headline-big {
  font-family: $tiempos;
  font-size: 48px;
  line-height: 57.6px;
  font-weight: $font-weight-bold;
  letter-spacing: -0.5px;
}

@mixin headline-medium {
  font-family: $tiempos;
  font-size: 36px;
  line-height: 43.6px;
  font-weight: $font-weight-bold;
  letter-spacing: -0.5px;
}

@mixin headline-small {
  font-family: $tiempos;
  font-size: 24px;
  line-height: 28.8px;
  font-weight: $font-weight-bold;
  letter-spacing: -0.2px;
}

// Text

@mixin text-headline {
  font-family: $maison-neue;
  font-size: 18px;
  line-height: 21.6px;
  font-weight: $font-weight-medium;
}

@mixin text-big {
  font-family: $maison-neue;
  font-size: 18px;
  line-height: 27px;
  font-weight: $font-weight-normal;
}

@mixin text-medium {
  font-family: $maison-neue;
  font-size: 16px;
  line-height: 24px;
  font-weight: $font-weight-medium;
}

@mixin text-small {
  font-family: $maison-neue;
  font-size: 14px;
  line-height: 21px;
  font-weight: $font-weight-medium;
}

// Subtitle

@mixin subtitle-small {
  font-family: $maison-neue;
  font-size: 12px;
  line-height: 14.4px;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}

@mixin subtitle-big {
  font-family: $maison-neue;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

// Grid

@mixin content-wrapper {
  max-width: $frame-width;
  margin: 0 auto;
}

@mixin grid-wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: $grid-gap;
  width: $main-grid-width;
  height: fit-content;
  margin: 0 auto;
}

// Responsive

@mixin desktop-breakpoint {
  @media only screen and (max-width: $desktop) {
    @content
  }
}

@mixin laptop-breakpoint {
  @media only screen and (max-width: $laptop) {
    @content
  }
}

@mixin tablet-breakpoint {
  @media only screen and (max-width: $tablet) {
    @content
  }
}

@mixin mobile-breakpoint {
  @media only screen and (max-width: $mobile) {
    @content
  }
}

@mixin not-mobile-breakpoint {
  @media only screen and (min-width: $mobile) {
    @content
  }
}

@mixin landscape {
  @media only screen and (orientation: landscape) {
    @content
  }
}
