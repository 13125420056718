@import "src/styles/v3/variables";
 
 .toastPosition {
  position: fixed;
  bottom: 32px;
  right: 32px;
  display: flex;
  z-index: 999999;
}

.bottom_right {
  bottom: 32px;
  right: 32px;
}

.top_right {
  top: 32px;
  right: 32px;
  bottom: auto;
}

.top {
  top: 16px;
  bottom: auto;
  width: 100%;
  justify-content: center;
  left: 0;
}