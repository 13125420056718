@import "src/styles/v2/variables";

.modalOverlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999
}

.modalContent {
  display: flex;
  flex-flow: column;
  width: 50vw;
  height: 100vh;
  position: relative;
  padding: 0;
  margin: 0 auto;
  background: #fff;
  text-align: center;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08), 0 4px 18px rgba(0, 0, 0, .08), 0 2px 16px -16px rgba(0, 0, 0, .08);
  overflow: initial;
  -webkit-overflow-scrolling: touch;
  border-radius: 16px;
  outline: none;

  @include mobile-breakpoint {
    width: 100vw;
    height: 100vh;
  }
}

.container {
  margin: 0 auto;
  z-index: 1999;
  position: relative;
}

.headerWrapper {
  display: flex;
  flex-flow: column;
  background-color: var(--grey100);
  padding: 16px 16px 0 16px;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include mobile-breakpoint {
      display: block;
    }

    .headerTitle {
      color: var(--grey800);
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      p {
        text-align: left;
      }
    }

    .user {
      display: flex;
      flex-flow: row;
      align-items: center;
    }

    .sessionId {
      display: inline-block;
      color: var(--grey400);
      font-size: 12px;
      margin-left: 4px;
    }

    .headerItem {
      color: #414148;
      text-align: left;

      span {
        font-weight: 500;
      }
    }

    .close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      padding: 0;
      background: transparent;
    }
  }

  .tabs {
    display: flex;
    position: relative;
    margin-top: 16px;
    justify-content: center;

    .tab {
      position: relative;
      height: 100%;
      display: inline-block;
      padding-bottom: 16px;
      color: var(--grey600);
      cursor: pointer;
      margin-left: 36px;
      font-size: 14px;

      &:hover {
        color: var(--grey500);
      }

      &:first-of-type {
        margin-left: 0;
      }
    }

    .activeTab {
      color: var(--grey800);

      &:after {
        content: " ";
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: var(--brand);
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
        z-index: 1000;
      }
    }
  }
}

.spinner {
  transform: scale(2);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.tableWrapper {
  flex: 1;
  overflow: auto;
  position: relative;
  padding: 0 8px 8px 8px;
}

.table {
  width: 100%;

  tr {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      top: 0;
      height: 100%;
      left: 0;
      right: 0;
      margin: auto;
      width: calc(100% - 48px);
      pointer-events: none;

    }

    &:last-of-type {
      &:after {
        content: none;
      }
    }
  }

  thead {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
  }

  th {
    line-height: 100%;
    width: fit-content;
    padding: 8px;
    white-space: nowrap;
  }

  td {
    padding: 8px;
    font-size: 13px;
    vertical-align: middle;
  }

  .nameColumn {
    width: 40%;
    text-align: left;
  }

  .nameCell {
    width: 100%;
    word-break: break-all;
    line-height: initial;
    display: flex;
    align-items: center;

    .playIcon {
      cursor: pointer;
      transform: scale(2);
      display: flex;
      align-items: center;
      width: 16px;
      margin-right: 8px;
      margin-left: 8px;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .folder {
    color: var(--grey500);
  }

  .downloadIcon {
    position: relative;
    top: 4px;
    cursor: pointer;
  }

  .sortArrow {
    position: relative;
    left: 8px;
  }
}

.itemMetaUploadButton {
  background-color: #FFF1ED;
  color: var(--brand);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: 16px 24px 16px 24px;

  .itemMetaUploadButtonIcon {
    margin-top: 2px;
    margin-right: 8px;
  }
}

.noFiles {
  margin-top: 36px;
  display: inline-block;
  color: #414148;
  text-align: center;
  width: 100%;
}

.rhap_container {
  margin-bottom: 8px;
  box-shadow: 0 -4px 0px -3px rgb(0 0 0 / 20%);

  .rhap_header {
    display: none;
  }
}

.playerIcon {
  position: relative;
  left: 8px;
  z-index: 100;
}


