@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .inputContainer {
    width: 100%;
    position: relative;

    textarea {
      width: 100%;
      height: 234px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;

      background-color: $grey-100;
      border: 1px solid $grey-100;
      border-radius: 8px;

      color: $grey-700;
      @include inter--regular-14;
      letter-spacing: 0.16px;
      outline: none;
      transition: all .15s;

      &:hover {
        background-color: $grey-50;
      }

      &:focus:not(.hasErrors, .noFocus) {
        border: 1px solid $grey-300;
        border-radius: 8px;
      }
    }

    .hasErrors {
      /* Grays/Grey50 */
      background:  $grey-50;
      border: 1px solid $danger-700;
      border-radius: 8px;
      @include inter--regular-14;
      color: $danger-700;
    }
  }

  .noFocus {
    input {
      @include inter--regular-14;
      color: $grey-400;
      &:focus {
        background: $grey-100;
        box-shadow: 0 8px 8px -8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 2px rgba(235, 234, 231, 0.15);
      }
    }
  }

  .disabled {
    input {
      color: $grey-400;
      cursor: not-allowed;
      &:hover {
        background-color: $grey-100;
      }

      &:focus {
        background: $grey-100;
      }
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: $grey-700 !important;
  }
}