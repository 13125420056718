@import "src/styles/v3/variables.scss";

.tabsContent {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.wrapper {
  // min-height: auto;
  margin-top: 32px;
  background-color: $background-color
}

.container {
  max-width: 768px;
  margin: 0 auto;
  padding-bottom: 120px;
}

@media (max-width: 768px) {
  .container {
    margin: 0 24px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.headerTitle {
  font-size: 18px;
  color: $grey-900;
  z-index: 2;
  @include inter--bold-24;
}


.formContentSmall {
  width: 60%;
}

@media (max-width: 768px) {
  .formContentSmall {
    width: 100%;
  }
}

.formContentLarge {
  width: 100%;
}

/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.headerTitle {
  font-size: 18px;
  color: $grey-900;
  z-index: 2;
  @include inter--bold-18;
}
