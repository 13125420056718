@import "src/styles/v3/variables";

.modal {
  background-image: url('./BG_Desktop.webp');
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  @include mobile-breakpoint {
    background-image: url('./BG_Mobile.webp');
  }
}

.container {
  display: flex;
  flex-direction: row;
  gap: 32px;
  text-align: left;
  color: $white;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    justify-content: flex-start;
  }

  @include mobile-breakpoint {
    flex-direction: column;
    flex-flow: column-reverse;
    text-align: center;
    align-items: flex-start;
  }

  .left {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    min-width: 360px;

    @include mobile-breakpoint {
      max-width: 100%;
      height: 100%;
      min-width: 0;
    }
  }

  .category {
    @include inter--regular-13;
    color: $attention-700;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .title {
    @include inter--bold-36;
    margin-bottom: 24px;

    @include mobile-breakpoint {
      @include inter--bold-24;
    }
  }

  .subtitle {
    @include inter--regular-24;
    margin-bottom: 24px;

    @include mobile-breakpoint {
      @include inter--bold-16;
    }
  }

  .span {
    font-weight: bold;
    color: $attention-700;
  }

  .description {
    @include inter--regular-16;
    margin-bottom: 40px;

    @include mobile-breakpoint {
      @include inter--regular-13;
    }
  }

  .button {
    width: fit-content;

    @include mobile-breakpoint {
      width: 100%;
    }
  }
}

.right {
  display: flex;

  .rightImage {
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 767px;

    @include mobile-breakpoint {
      width: 100%;
    }
  }
}

.learnMoreLink {
  color: $white;
  text-decoration: underline;
}