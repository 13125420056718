@import "src/styles/v3/variables";

.InputError {
  display: flex;
  gap: 6px;
  margin-top: 8px;
  color: $danger-700;
  flex-direction: row;
  flex-wrap: nowrap;
  // align-content: center;
  // align-items: center;
  // justify-content: center;
}

.icon {
  // position: relative;
  // top: -1px;
  // margin-right: 0;
  display: flex;
  align-items: center;


  path {
    fill: none;
    stroke: $danger-700;
    stroke-width: 1.5;
  }
}

.errorMessage {
  @include inter--regular-14;
}