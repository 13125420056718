.confirmation__calendarButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  /*margin-bottom: 16px;*/
  padding: 6px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid var(--grey200);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  color: var(--grey600);
  cursor: pointer;
  font-size: 14px;
  }


.confirmation__calendarButton svg {
  margin-right: 12px;
}