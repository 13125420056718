@import "src/styles/v3/variables";

.wrapper {
  background-color: $background-color;
  padding: 32px 24px;
  @include mobile-breakpoint {
    padding: 24px 16px;
  }
}

.container {
  max-width: 992px;
  margin: 0 auto;
  padding-bottom: 120px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

// .resourcesList {
//   display: flex;
//   flex-direction: row;
//   margin-top: 24px;
//   gap: 12px;
// }

.resourcesList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto 0 auto;
  grid-auto-rows: min-content;
  grid-gap: 24px;
  max-width: 1024px;

  @media only screen and (max-width: 1366px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 948px) {
    grid-template-columns: 1fr;
  }
}