@import "src/styles/v3/variables";

$header-height: 206px;

.container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 390px;

    @include inter--medium-14;
    color: $grey-500;

    @include mobile-breakpoint {
        overflow: auto;
        max-width: none;
        @include inter--regular-16;
        padding-top: $modal-padding-top;
        justify-content: flex-start;
    }
}

.titleBody {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title {
    @include inter--bold-16;
    color: $grey-700;

    @include mobile-breakpoint {
        @include inter--bold-24;
    }
}

.button {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
}