@import "src/styles/v3/variables";

$header-height: 206px;

.container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: calc($header-height - $modal-padding-top);
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 348px;

    @include inter--medium-14;
    color: $grey-500;

    @include mobile-breakpoint {
        overflow: auto;
        max-width: none;
        justify-content: flex-start;
    }
}
.containerRejoined {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 348px;

    @include inter--medium-14;
    color: $grey-700;

    @include mobile-breakpoint {
        padding-top: $modal-padding-top;
        overflow: auto;
        max-width: none;
        justify-content: flex-start;
    }
}

.header {
    position: absolute;
    width: calc(100% + calc($modal-padding-left + $modal-padding-right));
    left: (-$modal-padding-left);
    top: (-$modal-padding-top);
    height: $header-height;
    justify-content: center;
    overflow: hidden;
    background: $grey-900;
}

.image {
    display: flex;
    position: absolute;
    bottom: -47px;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
}


.titleTime {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title {
    @include inter--bold-16;
    color: $grey-700;
}

.phone {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
}

.phoneCopy {
    @include inter--bold-14;
}