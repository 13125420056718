@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 6px;
  background: $grey-50;

  align-items: stretch;

  /* Dark/dark-600 */
  border-radius: 8px;


  .breakdownRow {
    display: flex;
    // flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // padding: 80px;
    gap: 12px;

    .subtotal {
      @include inter--regular-13;
      color: $grey-700
    }

    .lightText {
      @include inter--regular-13;
      color: $grey-500;
      // text-align: center;
    }
  }

  .totalRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
    // padding: 0px;
    // gap: 12px;

    .text {
      @include inter--bold-13;
      color: $grey-700
    }
  }
}
