@import "src/styles/v2/variables";

.container {
  position: absolute;
  width: 100%;
  padding: 40px 48px;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  z-index: 3000;

  .logo {
    cursor: pointer;

    & > div {
      width: 154px;
      height: 36px;
    }
  }

  .signup,
  .login {
    cursor: pointer;
  }

  span {
    @include text-medium;
    color: $light-300;

    &:hover {
      color: $light-100;
    }
  }

  .steps {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    letter-spacing: .05rem;

    .progress {
      position: absolute;
      top: 0;
      height: 4px;
      left: 0;
      background: $brand-400;
    }

    .step {
      width: 33.33%;
      display: flex;

      span {
        @include text-headline;
        font-size: 14px;
        line-height: 14px;
        color: $dark-100;
        margin-left: 4px;
      }
    }

    .activeStep {
      span {
        color: $light-300;
      }
    }
  }

  .longerSteps {
    width: 627px;
  }
}

.mobileContainer {
  display: none;
}

/* Mobile */

@include tablet-breakpoint {
  .container {
    display: none;
  }

  .mobileContainer {
    display: block;
  }
}
