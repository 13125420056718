// Colors
$white:                 #FFFFFF !default;
$black:                 #000000 !default;

$dark-100:              #5C5C5C !default;
$dark-200:              #474747 !default;
$dark-300:              #2E2E2E !default;
$dark-400:              #262626 !default;
$dark-500:              #242424 !default;
$dark-600:              #1F1F1F !default;
$dark-700:              #131313 !default;

$light-100:             #FAFAFA !default;
$light-200:             #F3F3F2 !default;
$light-300:             #E6E6E5 !default;
$light-400:             #DAD9D8 !default;
$light-500:             #CDCDCB !default;
$light-600:             #C1C1BE !default;
$light-700:             #B4B4B1 !default;

$brand-100:             #F1DCA7 !default;
$brand-200:             #EACB7B !default;
$brand-300:             #E3BA4F !default;
$brand-400:             #E0B23B !default;
$brand-500:             #B0871C !default;
$brand-600:             #6E5411 !default;
$brand-700:             #2C2207 !default;

$new-brand-100:         #E1B441 !default;

$red-100:               #DFBEBD !default;
$red-200:               #AF3535 !default;
$red-300:               #7D2E29 !default;

$orange-100:            #E0BEB6 !default;
$orange-200:            #B84C33 !default;
$orange-300:            #853A0F !default;

$green-100:             #92CEA7 !default;
$green-200:             #2D7552 !default;
$green-300:             #1E3334 !default;

$blue-100:              #A4CCD8 !default;
$blue-200:              #2859B9 !default;
$blue-300:              #1A3A78 !default;

$sky-100:               #B2D4DE !default;
$sky-200:               #43869B !default;
$sky-300:               #0D566C !default;

$violet-100:            #B9A7C1 !default;
$violet-200:            #783897 !default;
$violet-300:            #4C2A5B !default;

$purple-100:            #B9B0D7 !default;
$purple-200:            #5439AD !default;
$purple-300:            #2A1A5E !default;

$pink-100:              #D9B2C5 !default;
$pink-200:              #AB4174 !default;
$pink-300:              #811F5F !default;

$pink-100:              #D9B2C5 !default;
$pink-200:              #AB4174 !default;
$pink-300:              #811F5F !default;

$facebook:              #1877f2 !default;
$twitter:               #1da1f2 !default;
$youtube:               #ff0000 !default;

// Fonts
$font-family-base:      Arial, sans-serif !default;
$font-family-base-serif:      "Times New Roman", "Georgia", serif !default;
$font-family-base-sans-serif:      Helvetica, sans-serif !default;

$tiempos:               'Tiempos', $font-family-base-serif !default;
$maison-neue:           'Maison Neue', $font-family-base-sans-serif !default;

// Font Weight
$font-weight-light:     300 !default !default;
$font-weight-normal:    400 !default !default;
$font-weight-medium:    500 !default !default;
$font-weight-semibold:  600 !default !default;
$font-weight-bold:      700 !default !default;

// Grid
$frame-width:           1440px;
$main-grid-width:       1128px;
$secondary-grid-width:  936px;
$grid-gap:              24px;

// Breakpoints
$mobile:                576px;
$tablet:                768px;
$laptop:                992px;
$desktop:               1128px;
