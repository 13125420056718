@import "src/styles/v3/variables";

.content {
  display: flex;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $grey-300;
  padding: 16px;
  gap: 4px;

  .wrapper {
    text-align: left;
    display: flex;
    flex-flow: row;
    align-items: center;
    color: $grey-700;
    gap: 6px;
    @include inter--medium-13;

    .description {
      color: $grey-700;
      @include inter--medium-13;
      @include mobile-breakpoint {
        justify-content: left;
        display: flex;
        flex-flow: row;
        width: 200px;
      }
      
      .totalCredits {
        padding-left: 4px;
      }
    }

    a {
      @include inter--medium-13;
      color: $grey-500;
      text-decoration: underline;
      padding-left: 4px;
    }

    p {
      color: $grey-700;
    }

    .preSessionSpecsIcon {
     /* margin-right: 12px;*/
     width: 15px;
     height: 15px;
      path {
        stroke: $brand-700;
      }
    }
  }
}
