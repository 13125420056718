@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 0px;
  gap: 10px;
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  gap: 6px;
  border-radius: 20px;
  background: $success-50;

  &.maxedOut {
    background: $grey-100;
  }

  .speed {
    display: flex;
    // align-items: center;
    @include inter--medium-14;
    color: $success-700;
    line-height: 100%;
  }

  .maxedOut {
    color: $grey-500;
  }

  .icon {
    display: flex;
    align-items: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.label {
  @include inter--regular-13;
  color: $grey-500;
  line-height: 100%;
}
.link {
  cursor: pointer;
  @include inter--regular-10;
  line-height: 100%;
  text-decoration: underline;
  color: $grey-500;
}

