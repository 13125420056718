@import "src/styles/v3/variables";
.container {
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  max-width: 600px;
  min-width: 250px;
  max-width: 503px;
  gap: 60px;

  @include mobile-breakpoint {
    gap: 32px;
    justify-content: flex-start;
    padding: 60px 0px;
  }

  .signupHeader {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 36px;
    width: 100%;
  }

  .infoText {
    @include inter--medium-14;
    color: $grey-500;
  }

  h1 {
    @include inter--bold-36;
    text-align: center;
    color: $grey-700;
    mix-blend-mode: normal;
    @include mobile-breakpoint {
      @include inter--bold-24;
      gap: 32px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    .button {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;
    }

    .alert {
      padding: 8px;
    }
  }
  .backToLogin {
    @include inter--medium-14;
    color: $grey-500;
  }
}