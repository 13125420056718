@import "src/styles/v2/variables";

.container {
  display: flex;
  flex-flow: column;
  margin-top: 24px;

  label {
    display: flex;
    flex-flow: column;
    @include text-medium;
    line-height: 19.41px;
    color: $light-700;
    letter-spacing: 0.16px;
    margin-bottom: 4px;
  }

  .inputContainer {
    position: relative;

    input {
      background-color: $dark-600;
      box-shadow: 0 8px 8px -8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 2px rgba(235, 234, 231, 0.15);
      border-radius: 4px;
      padding: 16px;
      color: $light-300;
      @include text-medium;
      letter-spacing: 0.16px;
      outline: none;
      transition: all .15s;

      &:hover {
        background-color: $dark-400;
      }

      &:focus:not(.hasErrors, .noFocus) {
        background: $dark-300;
        box-shadow: 0 8px 8px -8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 0 1px #E0B23B;
      }
    }

    .hasErrors {
      background: $dark-600;
      box-shadow: 0 8px 8px -8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 0 1px #B84C33;
    }

    .icon {
      cursor: pointer;
      position: absolute;
      right: 12px;
      bottom: 19px;

      path {
        transition: fill .15s;
      }

      &:hover path {
        fill: $light-700;
      }
    }
  }

  .noFocus {
    input {
      &:focus {
        background: $dark-600;
        box-shadow: 0 8px 8px -8px rgba(19, 19, 19, 0.15), 0 4px 4px rgba(19, 19, 19, 0.15), 0 2px 2px rgba(19, 19, 19, 0.15), inset 0 0 2px rgba(235, 234, 231, 0.15);
      }
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: $light-300 !important;
  }
}