@import "src/styles/v3/variables";

.modal.modal {
  width: 70vw;
  max-width: 640px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 24px;
  position: relative;

  @include mobile-breakpoint {
    width: 100vw;
  }
}
.container {
  display: flex;
  flex-flow: column;
  gap: 32px;
  
  .header {
    display: flex;
    flex-flow: column;

    .title {
      @include inter--bold-24;
      color: $grey-700;
    }
    .subtitle {
      @include inter--regular-16;
      color: $grey-500;
    }
  }

  .optionsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    @include mobile-breakpoint {
      display: flex;
      flex-direction: column;
    }
    .option {
      display: contents;
    }
  }

  .continue {
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 6px;
    @include mobile-breakpoint {
      display: flex;
      flex-direction: column;
    }
    .link {
      cursor: pointer;
      @include inter--medium-14;
      color: $brand-700;
    }
    .message {
      @include inter--medium-14;
      color: $grey-500;
    }
  }
}
  