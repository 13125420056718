@import "src/styles/v3/variables";

.facebookLoginContainer {
  width: 100%;
}

/*
 * Facebook Login Button
 */

.button {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  gap: 12px;
  background-color: $facebook;
  border-radius: 4px;
  @include inter--regular-13;
  color: $white;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}

.buttonText {
  display: flex;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
}

.buttonIcon {
  display: flex;
  width: 20px;
}



.emptyDiv {
  display: flex;
  width: 0px;

  @media (max-width: 899px) {
    // for screens smaller than 900px
    width: 20px;
  }
}