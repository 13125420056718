// Colors
$white:                 #FFFFFF !default;
$black:                 #000000 !default;

/* Attention */
$attention-900:          #866220 !default;
$attention-700:          #EAB30D !default;
$attention-300:          #FCE292 !default;
$attention-100:          #FCEBB8 !default;
$attention-50:           #FEF5DC !default;

/* Success */
$success-900:            #366964 !default;
$success-700:            #44837D !default;
$success-300:            #A1C0BE !default;
$success-100:            #CFDFDE !default;
$success-50:             #E7EFEF !default;

/* Danger */
$danger-900:             #992C2C !default;
$danger-700:             #C53939 !default;
$danger-300:             #E19B9B !default;
$danger-100:             #EFCDCD !default;
$danger-50:              #F7E6E6 !default;
$danger-5:               #FAE7E1 !default;

/* Brand */
$brand-900:              #AB4B2C !default;
$brand-700:              #DE6139 !default;
$brand-500:              #E58566 !default;
$brand-300:              #EDA892 !default;
$brand-100:              #F7DCD3 !default;
$brand-50:               #FBEEE9 !default;

/* Greys */
$grey-900:               #111827 !default;
$grey-700:               #374151 !default;
$grey-600:               #4B5563 !default;
$grey-500:               #6B7280 !default;
$grey-400:               #9CA3AF !default;
$grey-300:               #D1D5DB !default;
$grey-200:               #DADEE2 !default;
$grey-100:               #F3F4F6 !default;
$grey-50:                #F9FAFB !default;

/* Effect styles */
$shadow-gray-small:  0px 1px 4px rgba(14, 15, 57, 0.1);
$shadow-gray-medium:  0px 2px 16px rgba(14, 15, 57, 0.1);
$shadow-gray-large:  0px 2px 16px rgba(0, 0, 0, 0.2);
$shadow-orange-small:  0px 4px 4px rgba(222, 97, 57, 0.15);
$shadow-orange-medium:  0px 1px 8px rgba(222, 97, 57, 0.4);

/* Other colors */
$facebook: #1877F2;
// Fonts
$font-family-base-sans-serif:      Helvetica, sans-serif !default;

$inter:                           'Inter', $font-family-base-sans-serif !default;

// General template colors
$background-color:     $grey-100 !default;

// Font Weight
$regular:    400 !default !default;
$medium:    500 !default !default;
$bold:      700 !default !default;

// Grid
$frame-width:           1440px;
$main-grid-width:       1128px;
$secondary-grid-width:  936px;
$grid-gap:              24px;

// Breakpoints
$mobile:                576px;
$tablet:                768px;
$laptop:                992px;
$desktop:               1128px;

// Modal paddings
$modal-padding-top: 32px;
$modal-padding-right: 24px;
$modal-padding-bottom: 32px;
$modal-padding-left: 24px;