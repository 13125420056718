@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-width: 400px;
  max-width: 400px;

  @include inter--medium-14;
  color: $grey-700;

  @include mobile-breakpoint {
    margin-top: (-$modal-padding-top);
    margin-bottom: (-$modal-padding-bottom);
    overflow: auto;
    max-width: none;
    min-width: 0;
    justify-content: flex-start;
    height: 100vh;
    width: calc(100% + calc($modal-padding-left + $modal-padding-right));
    margin-left: (-$modal-padding-left);
  }
}

.image {
  display: flex;
  position: relative;
  width: calc(100% + calc($modal-padding-left + $modal-padding-right));
  top: calc(-#{$modal-padding-top} - 1px);
  margin-bottom: -7px;
  @include mobile-breakpoint {
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;

  @include mobile-breakpoint {
    padding: 0 24px 0 24px;
  }
}

.title {
  @include inter--bold-16;
  color: $grey-700;
  margin-bottom: 12px;
}

.subtitle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include inter--medium-14;
  color: $grey-500;
  margin-bottom: 16px;
}

.bullets {
  display: flex;
  padding: 12px;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  border-radius: 8px;
  background: $grey-50;
  @include inter--medium-13;
  color: $grey-500;
  margin-bottom: 24px;
}

.bullet {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.paymentOptions {
  display: flex;
  width: 100%;
  gap: 8px;
  margin-bottom: 24px;

  @include mobile-breakpoint {
    flex-wrap: wrap;
  }

  .radioOption {
    width: 100%;
  }
}

.secureCheckout {
  @include inter--medium-10;
  color: $grey-700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 8px;
  margin-bottom: -32px;

  path {
    stroke: $grey-900;
  }
}

.openBillingModalLink {
  cursor: pointer;
}

