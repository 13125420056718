@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 640px;
  @include inter--medium-14;
  color: $grey-500;

  @include mobile-breakpoint {
      overflow: auto;
      max-width: none;
      @include inter--regular-16;
      padding-top: $modal-padding-top;
      justify-content: flex-start;
  }
  .header {
    display: flex;
    flex-flow: column;

    .title {
      @include inter--bold-24;
      color: $grey-700;
    }
    .subtitle {
      @include inter--regular-16;
      color: $grey-500;
      margin-bottom: 8px;
    }
  }

  .optionsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    @include mobile-breakpoint {
      display: flex;
      flex-direction: column;
    }
    .option {
      display: contents;
    }
  }

  .continue {
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 6px;
    @include mobile-breakpoint {
      display: flex;
      flex-direction: column;
    }
    .link {
      cursor: pointer;
      @include inter--medium-14;
      color: $brand-700;
    }
    .message {
      @include inter--medium-14;
      color: $grey-500;
    }
  }
}
