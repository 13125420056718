.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 6px;

  .text {
    font-family: 'Maison Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */
    
    text-align: center;
    
    /* Light/light-700 */
    
    color: #B4B4B1;
  }

  .countryLink {
    font-family: 'Maison Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */
    
    text-align: center;
    
    /* Light/light-700 */
    color: #E0B23B;

  }
}
