@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;

  background: $grey-100;
  border: 1px solid $grey-300;
  border-radius: 8px;

  &.hasError {
    border: 1px solid $danger-300;
    background: $danger-50;
  }
}

.filenameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  .icon {
    position: relative;
    top: -1px;
    display: flex;
    align-items: center;
  
    path {
      stroke: $grey-500;
    }
    &.hasError {
      path {
        stroke: $white;
      }
    }
  }
  
  .folderName {
    color: $grey-700;
    @include inter--regular-14;
  }
}

.actionContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 12px;

  .errorMessage {
    @include inter--regular-14;
    color: $danger-700;
  }
  .action {
    cursor: pointer;
    @include inter--regular-14;
    color: $grey-400;
    text-decoration-line: underline;

    &.hasError {
      color: $danger-700;
    }
  }
}
