@import "src/styles/v3/variables";

.label {
  display: block;
  @include inter--regular-14;
  color: $grey-700;
}

.selectIcon {
  position: absolute;
  content: "&";
  top: 0;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  right: 16px;
  display: flex;
  align-items: center;
}
.option {
  cursor: not-allowed;
}