@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 396px;
  @include mobile-breakpoint {
    width: 88vw;
  } 
  top: 100px;
  left: calc(50% - 198px);
  border-radius: 8px;
  gap: 32px;

  .titleContainer {
    display: flex;
    flex-direction: column;
    gap: 0px;

    .name {
      @include inter--bold-18;
      color: $grey-700;
    }
  
    .title {
      @include inter--medium-14;
      color: $grey-500;
    }
  }
  .description {
    @include inter--regular-14;
    color: $grey-500;
    text-align: left;
  }
  .button {
    text-transform: none;
  }
}
