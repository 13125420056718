@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 396px;
  top: 100px;
  left: calc(50% - 198px);
  border-radius: 8px;
  gap: 32px;

  .textContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      @include inter--bold-16;
      color: $grey-700;
    }
  
    .paragraph {
      @include inter--medium-14;
      color: $grey-500;
    }
  
    .thanks {
      @include inter--medium-14;
      color: $grey-500;
    }
  }
}
