.light {
  background: #F9FAFB;
}

.dark {
  background: #1F1F1F;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  align-items: stretch;

  /* Dark/dark-600 */
  border-radius: 8px;


  .breakdownRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;

    .text {
      font-family: 'Maison Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #5C5C5C;
    }

    .lightBlackText {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      /* identical to box height, or 20px */

      text-align: center;

      /* Grays/Grey 700 */

      color: #374151;
    }

    .lightText {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      /* identical to box height, or 20px */

      text-align: center;

      /* Grays/Grey 500 */

      color: #6B7280;
    }
  }

  .totalRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;

    .text {
      font-family: 'Maison Neue';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #B4B4B1;
    }
  }
}
