@import "src/styles/v3/variables";

$header-height: 176px;

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: $header-height;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-width: 540px;

  @include inter--regular-14;
  color: $grey-700;

  @include mobile-breakpoint {
    margin-top: $modal-padding-top;
    overflow: auto;
    max-width: none;
    justify-content: flex-start;
    min-width: 0;
  }
}

.header {
  position: absolute;
  width: calc(100% + calc($modal-padding-left + $modal-padding-right));
  left: (-$modal-padding-left);
  top: (-$modal-padding-top);
  height: $header-height;
  justify-content: center;
  background: linear-gradient(180deg, #5a38fc 0%, rgba(90, 56, 252, 0) 100%), #111827;
}

.image {
  display: flex;
  position: absolute;
  height: 194px;
  bottom: -47px;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}

.titleTime {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  @include inter--bold-24;
}

.time {
  @include inter--regular-16;
  padding-bottom: 16px;
}

.phoneCopy,
.bodyCall {
  @include inter--bold-14;
  margin-bottom: 16px;
}

.bodyCall {
  @include inter--regular-16;
  color: $grey-500;
  max-width: 370px;
}

.phoneButton {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}