@import "src/styles/v2/variables";

$images-z-index: 1000;
$backdrop-z-index: 2000;
$content-z-index: 3000;

.container {
  background: $dark-700;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mobile-breakpoint {
    padding-bottom: 100px;
  }

  .leftImage, .rightImage {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    width: 30%;

    &:after {
      position: absolute;
      content: '';
      width: calc(100% + 4px);
      height: 100%;
      top: 0;
      left: -2px;
      background: linear-gradient(0deg, rgba(19, 19, 19, 0.9), rgba(19, 19, 19, 0.9)), linear-gradient(90deg, #131313 0%, rgba(19, 19, 19, 0.991353) 6.67%, rgba(19, 19, 19, 0.96449) 13.33%, rgba(19, 19, 19, 0.91834) 20%, rgba(19, 19, 19, 0.852589) 26.67%, rgba(19, 19, 19, 0.768225) 33.33%, rgba(19, 19, 19, 0.668116) 40%, rgba(19, 19, 19, 0.557309) 46.67%, rgba(19, 19, 19, 0.442691) 53.33%, rgba(19, 19, 19, 0.331884) 60%, rgba(19, 19, 19, 0.231775) 66.67%, rgba(19, 19, 19, 0.147411) 73.33%, rgba(19, 19, 19, 0.0816599) 80%, rgba(19, 19, 19, 0.03551) 86.67%, rgba(19, 19, 19, 0.0086472) 93.33%, rgba(19, 19, 19, 0) 100%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
      z-index: 1;
    }

    & > div {
      height: 100%;
    }
  }

  .leftImage .bg,
  .rightImage .bg {
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/images/signup-flow/left.webp');
    background-position: left bottom;;
    background-repeat: repeat-y;
    background-size: contain;
    //animation: MoveUpDown 20s linear infinite;
  }

  .rightImage .bg {
    background-image: url('../../../assets/images/signup-flow/right.webp');
    background-position: right bottom;
  }

  .rightImage {
    left: initial;
    right: 0;

    &:after {
      position: absolute;
      content: '';
      width: calc(100% + 4px);
      height: 100%;
      top: 0;
      right: -2px;
      background: linear-gradient(0deg, rgba(19, 19, 19, 0.9), rgba(19, 19, 19, 0.9)), linear-gradient(90deg, #131313 0%, rgba(19, 19, 19, 0.991353) 6.67%, rgba(19, 19, 19, 0.96449) 13.33%, rgba(19, 19, 19, 0.91834) 20%, rgba(19, 19, 19, 0.852589) 26.67%, rgba(19, 19, 19, 0.768225) 33.33%, rgba(19, 19, 19, 0.668116) 40%, rgba(19, 19, 19, 0.557309) 46.67%, rgba(19, 19, 19, 0.442691) 53.33%, rgba(19, 19, 19, 0.331884) 60%, rgba(19, 19, 19, 0.231775) 66.67%, rgba(19, 19, 19, 0.147411) 73.33%, rgba(19, 19, 19, 0.0816599) 80%, rgba(19, 19, 19, 0.03551) 86.67%, rgba(19, 19, 19, 0.0086472) 93.33%, rgba(19, 19, 19, 0) 100%);
      transform: matrix(1, 0, 0, -1, 0, 0);
      z-index: 2;
    }
  }

  .content {
    z-index: 2000;
    // TODO: Restore if this gives issues
    // margin: auto;

    @include tablet-breakpoint {
      min-width: 320px;
    }

    @include mobile-breakpoint {
      min-width: 300px;
      max-width: 400px;
    }

    h1 {
      @include headline-medium;
      // color: $light-300;
      color: #F2F2F2;
      margin: 108px auto 40px auto;
      text-align: center;
      white-space: break-spaces;
      z-index: 1000;
    }

    .planInfo {
      background: #131313;
      box-shadow: 0 8px 8px -8px #131313, 0 4px 4px #131313, 0 2px 2px #131313, inset 0 0 2px rgba(235, 234, 231, 0.5);
      border-radius: 4px;
      min-height: 112px;
      height: fit-content;
      display: flex;
      flex-flow: column;
      width: 360px;

      @include tablet-breakpoint {
        width: 100%;
      }

      .topBar {
        height: 30px;
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        position: relative;
        border-bottom: 1px solid $dark-300;

        .selectedPlan, .changePlan {
          @include text-small;
          font-size: 12px;
          line-height: 14.56px;
          letter-spacing: 0.4px;
          // color: $light-700;
          color: $dark-100;
        }

        .changePlan {
          color: $brand-400;
          cursor: pointer;
          transition: color .15s;

          &:hover {
            color: $brand-200;
          }
        }
      }

      .planContent {
        display: flex;
        padding: 16px;
        justify-content: space-between;
        align-items: center;

        .logo {
          width: 48px;
          height: 48px;
          border-radius: 4px;
          background: $brand-400;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .descriptions {
          padding-left: 12px;
          flex: 1;
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          height: fit-content;
          padding-top: 4px;
          max-width: 280px;

          h4, p {
            @include text-small;
            line-height: 17px;
            color: $light-300;
            margin-bottom: 4px;

            span {
              letter-spacing: 0.5px;
              letter-spacing: 1px;
              text-transform: uppercase;
            }
          }

          p {
            color: $light-700;
          }
        }
      }
    }

    .form {
      display: flex;
      flex-flow: column;
      width: 360px;
      margin: 0 auto;
      z-index: 1000;
    }

    .buttonContainer {
      margin-top: 24px;
      width: 100%;
    }

    .bullets {
      margin: 24px auto 64px auto;
      display: flex;
      flex-flow: column;

      @include tablet-breakpoint {
        width: 340px;
      }

      > div {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        .tick {
          position: relative;
          top: -1px;
        }

        span {
          margin-left: 8px;
          color: $light-700;
          @include text-small;
        }
      }
    }
  }
}

/* Image Animation */
@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10rem);
  }
}

@keyframes MoveDownUp {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10rem);
  }
}

@include tablet-breakpoint {
  .container {
    &:before {
      content: " ";
      position: absolute;
      height: 100%;
      width: 100%;
      //background:  linear-gradient(180deg, rgba(19, 19, 19, 0.7) -4.97%, rgba(19, 19, 19, 0.702594) 2.03%, rgba(19, 19, 19, 0.710653) 9.03%, rgba(19, 19, 19, 0.724498) 16.02%, rgba(19, 19, 19, 0.744223) 23.02%, rgba(19, 19, 19, 0.769532) 30.02%, rgba(19, 19, 19, 0.799565) 37.02%, rgba(19, 19, 19, 0.832807) 44.02%, rgba(19, 19, 19, 0.867193) 51.01%, rgba(19, 19, 19, 0.900435) 58.01%, rgba(19, 19, 19, 0.930468) 65.01%, rgba(19, 19, 19, 0.955777) 72.01%, rgba(19, 19, 19, 0.975502) 79.01%, rgba(19, 19, 19, 0.989347) 86%, rgba(19, 19, 19, 0.997406) 93%, #131313 100%);
      background-color: $dark-700;
      z-index: $backdrop-z-index;
    }

    .leftImage,
    .rightImage {
      animation: none;
    }

    .form {
      width: 100%;
      padding: 0 1.6rem;
    }
  }
}

.ondemand {
  margin-top: 32px;
}

/* Black Friday styles */
.blackFridaycontent {
  z-index: 2000;
  margin: auto;
  margin-top: 100px;
  // margin-bottom: 100px;

  @include tablet-breakpoint {
    min-width: 320px;
    margin: 0;
    margin-top: 50px;
  }

  @include mobile-breakpoint {
    min-width: 300px;
    max-width: 400px;
    margin-top: 100px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 60px;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }

  .mainTitle {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    @include mobile-breakpoint {
      margin-top: 0px;
    }
  }


  .blackFridayHeader {
    @include headline-medium;
    color: #F2F2F2; // $light-300;
    text-align: center;
    white-space: break-spaces;
    z-index: 1000;

    @include mobile-breakpoint {
      font-size: 26px;
    }
  }
  
  .subheaderTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 125%;
    /* or 45px */
  
    display: flex;
    align-items: center;
    text-align: center;
  
    /* Brand/brand-400 */
  
    color: white;
  }
  
  .allPlansContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 40px 32px;
    gap: 36px;

    @include mobile-breakpoint {
      padding: 0;
      height: 0;
      width: 100%;
    }
  }
}

.highlighted {
  color:  #E0B23B;
  padding-right: 4px;
}

.ondemandParagraph {
  margin-top: 24px;
  .highlighted {
    color: #E0B23B;
  }
  .freeplanLink {
    color: #B4B4B1;
    text-decoration: underline;
    cursor: pointer;
    padding-right: 4px;
  }
}