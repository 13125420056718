@import 'src/styles/v2/variables';

.filtersContainer {

  @include mobile-breakpoint {
    height: fit-content;
    position: fixed;
    // top: 64px;
    z-index: 5;
  }

  .proFilters {
    flex: 0 1 auto;
    width: 248px;
    min-width: 248px;
    background: var(--white);
    box-shadow: 0 2px 8px rgba(14, 15, 57, 0.05);
    z-index: 5;
    padding: 16px 16px 0 16px;
    display: flex;
    flex-flow: column;
    position: sticky;
  }

  .clearFilters {
    position: static;
    top: 16px;
    margin-left: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--brand);
    width: fit-content;
    display: block;
    cursor: pointer;
    margin-bottom: 8px;

    &:hover {
      color: var(--brand400);
    }
  }

  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--grey500);
    margin-bottom: 12px;
  }

  .dateRangePicker {
    display: flex;
    flex-direction: column;
  }

  .timezone {
    display: flex;
    align-items: center;
    /*padding-left: 12px;*/
    margin: 0 0 16px 0;

    span {
      margin-left: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--grey500);
    }
  }

  .serviceFilter {
    padding-top: 16px;
    flex: 1;
    overflow: hidden;

    &.separation {
      border-top: 1px solid var(--grey200);
    }

    .inputContainer {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 8px;

      .input {
        width: 100%;
        padding: 12px;
        margin-bottom: 4px;
        border-radius: var(--radius-medium);
        font-size: var(--fontsize-14);
        color: var(--grey800);
        border: 1px solid var(--grey300);
        outline: none;
      }

      .input:focus {
        background-color: var(--white);
        border-radius: var(--radius-medium);
        outline: none;
        border-color: var(--brand);
      }

      .icons {
        display: flex;
        align-items: center;
        position: absolute;
        right: 8px;
        top: 12px;
        margin: auto;

        .closeIcon {
          transform: scale(0.65);
          cursor: pointer;
        }

        .dropdownIcon {
          cursor: pointer;
        }
      }
    }

    .list {
      display: flex;
      flex-flow: column;
      overflow: auto;
      padding-bottom: 8px;
      max-height: calc(100% - 60px);

      @include mobile-breakpoint {
        max-height: initial;
      }

      .checkbox {
        margin: 4px 0;

        span {
          div {
            border-color: #DDDDDD !important;
            transform: scale(0.85);
          }
        }
      }

      .checked {
        span {
          div {
            border-color: rgb(222, 97, 57) !important;
            background: rgb(222, 97, 57);
          }
        }
      }

      .label {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.01em;
      }
    }
  }

  .proFiltersMobile {
    position: fixed;
    left: 0%;
    right: 0%;
    bottom: auto;
    width: 100vw;
    background: transparent;
    z-index: 5;
    pointer-events: none;
    display: flex;
    flex-flow: column;
    overflow: hidden;

    @include mobile-breakpoint {
      position: relative;
      top: 0;
    }


    .chipsContainer {
      pointer-events: auto;
      background: #FFFFFF;
      box-shadow: 0 2px 8px rgba(14, 15, 57, 0.05);
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;

      .chips {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 4px;
        padding: 8px 16px;
      }


      .appliedFiltersButtons {
        display: flex;
        justify-items: flex-end;
        align-self: flex-end;
        padding-right: 24px;
        padding-bottom: 8px;

        button,
        span {
          width: fit-content;
          font-size: 14px;
          line-height: 17px;
          color: var(--brand);
          background: transparent;
        }

        button {
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }
        }

        span {
          margin: 0 4px;
        }
      }
    }

    .buttonContainer {
      width: 100vw;
      height: 69px;
      background: var(--white);
      box-shadow: 0 2px 8px rgba(14, 15, 57, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: auto;
      z-index: 5;
      padding: 0 24px;

      @include mobile-breakpoint {
        padding: 0 16px;
      }

      ;

      button {
        width: 320px;
        height: 41px;
        border: 1px solid var(--grey200);
        border-radius: 24px;
        background: transparent;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: var(--grey500);

        @include mobile-breakpoint {
          width: 100%;
        }
      }

      .buttonIsOpen {
        background: var(--brand);
        color: var(--white);
      }
    }

    .mobileScrollContent {
      background: var(--white);
      height: 100%;
      position: fixed;
      top: 64px;
      width: 100%;
      z-index: 5;

      .mobileFiltersContent {
        background: var(--white);
        pointer-events: auto;
        padding: 0 16px;
        overflow: auto;
        // height: calc(100% - 69px);
        padding-bottom: 80px;
        z-index: 5;
      }
    }

    .mobileScrollContentOpen {
      height: 100%;
      margin: 0em;
      overflow-y: auto;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      z-index: 5;
      height: 64px;
      width: 100%;
      background: var(--white);
      pointer-events: auto;
      margin-bottom: 8px;
      margin-top: 8px;

      .searchButton {
        width: 50%;
        min-width: 160px;
        max-width: 220px;
        height: 48px;
      }

      .clearFilters {
        margin: auto;
      }

      svg {
        display: block;
        margin: 8px;
        pointer-events: auto;
      }
    }
  }

  .positionRelative {
    position: relative;
  }

  .uppercase {
    text-transform: uppercase;
  }
}