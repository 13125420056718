/* Regular */

@mixin inter--regular-10 {
  font-family: $inter;
  font-size: 10px;
  line-height: 150%;
  font-weight: $regular;
}

@mixin inter--regular-13 {
  font-family: $inter;
  font-size: 13px;
  line-height: 150%;
  font-weight: $regular;
}

@mixin inter--regular-14 {
  font-family: $inter;
  font-size: 14px;
  line-height: 150%;
  font-weight: $regular;
}

@mixin inter--regular-16 {
  font-family: $inter;
  font-size: 16px;
  line-height: 150%;
  font-weight: $regular;
}

@mixin inter--regular-18 {
  font-family: $inter;
  font-size: 18px;
  line-height: 125%;
  font-weight: $regular;
}

@mixin inter--regular-24 {
  font-family: $inter;
  font-size: 24px;
  line-height: 125%;
  font-weight: $regular;
}

@mixin inter--regular-36 {
  font-family: $inter;
  font-size: 36px;
  line-height: 110%;
  font-weight: $regular;
}

/* bold */

@mixin inter--medium-10 {
  font-family: $inter;
  font-size: 10px;
  line-height: 150%;
  font-weight: $medium;
}

@mixin inter--medium-13 {
  font-family: $inter;
  font-size: 13px;
  line-height: 150%;
  font-weight: $medium;
}

@mixin inter--medium-14 {
  font-family: $inter;
  font-size: 14px;
  line-height: 150%;
  font-weight: $medium;
}

@mixin inter--medium-16 {
  font-family: $inter;
  font-size: 16px;
  line-height: 150%;
  font-weight: $medium;
}

@mixin inter--medium-18 {
  font-family: $inter;
  font-size: 18px;
  line-height: 125%;
  font-weight: $medium;
}

@mixin inter--medium-24 {
  font-family: $inter;
  font-size: 24px;
  line-height: 125%;
  font-weight: $medium;
}

@mixin inter--medium-36 {
  font-family: $inter;
  font-size: 36px;
  line-height: 110%;
  font-weight: $medium;
}

  /* Bold */

@mixin inter--bold-10 {
  font-family: $inter;
  font-size: 10px;
  line-height: 150%;
  font-weight: $bold;
}

@mixin inter--bold-13 {
  font-family: $inter;
  font-size: 13px;
  line-height: 150%;
  font-weight: $bold;
}

@mixin inter--bold-14 {
  font-family: $inter;
  font-size: 14px;
  line-height: 150%;
  font-weight: $bold;
}

@mixin inter--bold-16 {
  font-family: $inter;
  font-size: 16px;
  line-height: 150%;
  font-weight: $bold;
}

@mixin inter--bold-18 {
  font-family: $inter;
  font-size: 18px;
  line-height: 125%;
  font-weight: $bold;
}

@mixin inter--bold-24 {
  font-family: $inter;
  font-size: 24px;
  line-height: 125%;
  font-weight: $bold;
}

@mixin inter--bold-36 {
  font-family: $inter;
  font-size: 36px;
  line-height: 110%;
  font-weight: $bold;
}

// Responsive

@mixin desktop-breakpoint {
  @media only screen and (max-width: $desktop) {
    @content
  }
}

@mixin laptop-breakpoint {
  @media only screen and (max-width: $laptop) {
    @content
  }
}

@mixin tablet-breakpoint {
  @media only screen and (max-width: $tablet) {
    @content
  }
}

@mixin mobile-breakpoint {
  @media only screen and (max-width: $mobile) {
    @content
  }
}

@mixin not-mobile-breakpoint {
  @media only screen and (min-width: $mobile) {
    @content
  }
}

@mixin landscape {
  @media only screen and (orientation: landscape) {
    @content
  }
}
