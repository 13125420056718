@import "src/styles/v3/variables";

.availabilityCalendar {
  width: 100%;
  height: calc(100% - 8px);
  position: relative;
  
  .description {
    text-align: center;
    font-weight: 500;
    color: var(--grey500);
    font-size: 14px;
    letter-spacing: -0.3px;
    white-space: break-spaces;
    margin: 8px auto 36px auto;
    width: 77%;

    @include mobile-breakpoint {
      text-align: left;
      white-space: initial;
    }
  }
}

.availabilityCalendarPagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.availabilityCalendarPagination__title {
  color: $grey-600;
}

.timezoneTitle {
  padding-left: 5px;
  color: $grey-400;
}

.availabilityCalendarPagination__button {
  font-size: 0;
  padding: 12px 16px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid $grey-300;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.availabilityCalendarPagination__buttonDisabled {
  font-size: 0;
  padding: 12px 16px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid $grey-200;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.75;
}

.availabilityCalendarPagination__buttonDisabled svg path {
  stroke: $grey-200;
}

/* Slots Picker */
.slotsTable {
  display: flex;
  margin-bottom: 22px;
}

.slotsTable__column {
  padding: 16px 8px;
  border-radius: 8px;
  background-color: $grey-50;
  width: 14.285%;

  @include tablet-breakpoint {
    width: 100%;
  }
}

.slotsTable__column + .slotsTable__column {
  margin-left: 8px;
}

.slotsTable__columnHead {
  margin-bottom: 12px;
}

.slotsTable__dayName {
  color: $grey-400;
}

.slotsTable__dayNumber {
  color: $grey-600;
  font-size: 18px;
}

.slotsTable__slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: var(--white);
  font-size: 13px;
  box-shadow: 0 1px 4px rgba(14, 15, 57, 0.05), 0 1px 2px rgba(14, 15, 57, 0.05);
  cursor: pointer;
  border: 1px solid transparent;
  font-weight: 500;
  position: relative;

  .slotType {
    position: absolute;
    bottom: -1px;
    right: 4px;
    font-size: 9px;
    color: $grey-900;
  }

  .fixedSlot {
    color: $brand-700;
  }

  &.top {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    padding-bottom: 17px;
    box-shadow: none;
  }

  &.middle {
    border-top: none;
    border-bottom: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    padding-bottom: 18px;

    * {
      visibility: hidden;
    }
  }

  &.bottom {
    padding-top: 9px;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;

    * {
      visibility: hidden;
    }
  }
}

.slotsTable__slot.slotHidden {
  display: none;
}

.slotsTable__slot.isSelected {
  background-color: #FFF5F2;
  border-color: $brand-700;
  color: $brand-700;
  cursor: pointer;
  display: flex;
}

.slotsTable__slot.isSelected .slotsTable__slotTime,
.slotsTable__slot.isSelected .slotsTable__slotPeriod {
  color: $brand-700;
}

.slotsTable__slotTime {
  color: $grey-600;
}

.slotsTable__slotPeriod {
  color: $grey-400;
  text-transform: uppercase;
}

.slotPrebooked {
  background-color: $grey-50;
  border-color: var(--brand100);
}

/* Different States of the timeslot */
.slotRestricted {
  color: $grey-400;
  background: $grey-200;
  mix-blend-mode: normal;
  border-radius: 8px;
  text-decoration: line-through;
  box-shadow: none;
  cursor: not-allowed;

  .slotsTable__slotTime {
    color: $grey-400;
  }
  .slotsTable__slotPeriod {
    color: $grey-400;
  }
}

.slotIcon {
  position: absolute;
  left: 42.54%;
  right: 42.2%;
  path {
    stroke: #374151;
  }

  @include mobile-breakpoint {
    left: 92.28%;
    right: 4.68%;
  }
}

.prebookedAsBooked,
.slotsTable__slot.booked,
.slotsTable__slot.booked .slotsTable__slotTime,
.slotsTable__slot.booked .slotsTable__slotPeriod {
  background-color: $grey-200;
  color: $grey-400;
  text-decoration: line-through;
  box-shadow: none;
  cursor: not-allowed;

  &.slotRestricted {
    background: $grey-200;
    mix-blend-mode: normal;
    border-radius: 8px;
    cursor: not-allowed;
  
    .slotsTable__slotTime {
      color: $grey-400;
      background-color: $grey-200;
    }
    .slotsTable__slotPeriod {
      background-color: $grey-200;
      color: $grey-400;
    }
  }
}

.prebookedAsBooked {
  border: none;

  .slotsTable__slotTime {
    color: $grey-400;
  }
}

.availabilityCalendar__timezone {
  display: flex;
  align-items: center;
  margin-top: 8px;
  border-radius: 12px;
  font-size: 14px;
  color: $grey-700;

  p {
    margin-left: 8px;
  }
}

.availabilityCalendar__timezoneIcon {
  font-size: 0;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.availabilityCalendar__note {
  display: block;
  width: 100%;
  padding: 80px 0;
  text-align: center;
}

.availabilityCalendar__noteIcon {
  margin-bottom: 12px;
}

.availabilityCalendar__noteText {
  color: $grey-700;
}

.noAvailableSlots {
  background-color: white;
  opacity: 0.75;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  top: 0;

  p {
    color: var(--black);
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }
}

/* Mobile */

//@media (max-width: 992px) {
//  .availabilityCalendar {
//    width: 40%;
//  }
//
//  .slotsTable__column {
//    margin-left: 8px;
//    width: 100%;
//  }
//}

@media (max-width: 576px) {
  .availabilityCalendar {
    width:  100%;
  }

  .slotsTable__slot {
    margin-bottom:  12px;
    padding: 16px 12px 16px 24px;
    font-size: 16px;
    justify-content: normal;
    box-shadow: 0 1px 4px rgba(14, 15, 57, 0.1), 0 1px 2px rgba(14, 15, 57, 0.1);
  }

  .slotsTable__slotTime {
    margin-right:  4px;
  }
}

.isFullWidth {
  width: 100%;
  position: relative;
}

