@import "src/styles/v2/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 36px;
  
  // width: 448px;

  @include mobile-breakpoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 0px 40px;
    gap: 0px;

    // width: 366px;
  }

  .header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    /* Grays/Grey 500 */
    
    color: #6B7280;

    @include mobile-breakpoint {
      margin-bottom: 32px;
      top: 12px;
    }
  }

  .plansContainer {
    width: 100%;
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;

    @include not-mobile-breakpoint {
      /* Scrollable */
      box-sizing: border-box;
      max-height: 405px;
      overflow-y: scroll;
      overflow-x: hidden;

      padding-right: 15px;
      margin-right: -15px;
      /** **** **/
    }

    .divider {
      width: 100%;
      margin: 10px 0;
      border: 1px solid #DADEE2;
    }

    .sectionTitle {
      width: 100%;
      margin: 10px 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      /* Grays/Grey 500 */

      color: #6B7280;
    }

    .plan {
      width: 100%;
      height: fit-content;
      padding: 12px 16px;
      background: #F3F4F6;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid #F3F4F6;

      @include mobile-breakpoint {
        padding: 6px;
      }

      &:hover {
        opacity: 0.9;
      }

      &:last-of-type {
        margin: 0;
      }

      .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 12px;
      }

      .mediumRow {
        margin: 4px 0 0 0;
      }

      .bigText {
        font-size: 16px;
        line-height: 19.36px;
        color: #374151;
        &.highlight {
          color: #DD6039;
        }
        font-weight: 700;

        @include mobile-breakpoint {
          font-size: 14px;
        }
      }
      
      .creditsPeryearIcon {
        margin-left: 24px;

        path {
          stroke: #6B7280;
        }
      }

      .bigTextGray {
        color: $dark-100;
      }

      .mediumText {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 4px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        
        color: #6B7280;

        @include mobile-breakpoint {
          font-size: 14px;
        }
      }

      .mediumTextLight {
        font-weight: 400;
      }

      .smallText {
        font-size: 10px;
        line-height: 12px;
        color: #938C8C;
        font-weight: 600;
        display: flex;
        align-items: center;

        .icon {
          margin-left: 2px;
          transform: scale(0.85);
        }
      }
    }

    .selectedPlan {
      border: 1px solid #DE6139;
      // background: rgba(222, 97, 57, 0.1);
      background: white;
    }
  }

  .bullets {
    text-align: left;
    list-style-type: disc;
    list-style-position: inside;

    li {
      color: var(--grey700);
      font-size: 12px;
      line-height: 24px;
    }
  }
  .totalAmountMessage {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 20px */

    display: flex;
    align-items: flex-end;
    text-align: center;

    /* Grays/Grey 500 */

    color: #6B7280;
  }

  .VATBreakdown {
    width: 100%;
  }

  .buttonFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .plans__planButton {
    width: 100%;
    padding: var(--button-padding);
    background-color: var(--button-background);
    border-radius: var(--button-radius);
    font-size: var(--fontsize-15);
    box-shadow: var(--button-boxShadow);
    color: var(--button-textColor);
    text-align: center;
    cursor: pointer;
    outline: none;
    font-family: "Inter", sans-serif;
    font-weight: 400;

    &:hover {
      opacity: 0.9;
    }
  }

  .plans__planButton.isCurrent {
    background-color: var(--grey200);
    color: var(--grey600);
    box-shadow: none;
    cursor: default;
  }
}
.continueButton {
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  cursor: pointer;

  display: flex;
  align-items: flex-end;
  text-align: center;
  text-decoration-line: underline;
}

.switchPlanExplanation {
  font-weight: 300;
  font-style: italic;
  font-size: 13px;
  padding-top: 20px;
}

.paymentMethodModal {
  width: 80%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: auto;

  h1 {
    position: relative;
    top: -8px;
    color: var(--grey400);
    margin-bottom: 16px;
    font-size: 18px;
  }

  .methods {
    display: flex;
    justify-content: center;

    .method {
      width: 180px;
      height: 144px;
      background: var(--grey200);
      border-radius: 8px;
      border: 1px solid var(--grey200);
      box-shadow: 0 2px 16px rgb(14 15 57 / 5%), 0 2px 8px rgb(14 15 57 / 5%);
      margin: 0 16px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover:not(.methodSelected) {
        background-color: var(--grey100);
      }

      p {
        font-family: "Inter", sans-serif;
        margin-bottom: 22px;
        font-size: 20px;
      }
    }

    .methodSelected {
      border: 1px solid var(--brand);
      background-color: #FFF1ED;
      cursor: auto;
    }
  }

  .joinButton {
    width: calc(100% - 16px);
    margin-top: 24px;
    padding: var(--button-padding);
    background-color: var(--button-background);
    border-radius: var(--button-radius);
    font-size: var(--fontsize-16);
    box-shadow: var(--button-boxShadow);
    color: var(--button-textColor);
    text-align: center;
    cursor: pointer;
    outline: none;
    font-family: "Inter", sans-serif;
  }
}

.rolloverWarningModal {
  width: 80%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: auto;

  h1 {
    position: relative;
    top: -8px;
    color: var(--grey400);
    margin-bottom: 16px;
    font-size: 18px;
  }

  .rolloverInfoContainer {
    text-align: left;
    margin-bottom: 8px;
    border: none;
  }

  .joinButton {
    width: calc(100% - 16px);
    margin-top: 8px;
    padding: var(--button-padding);
    background-color: var(--button-background);
    border-radius: var(--button-radius);
    font-size: var(--fontsize-16);
    box-shadow: var(--button-boxShadow);
    color: var(--button-textColor);
    text-align: center;
    cursor: pointer;
    outline: none;
    font-family: "Inter", sans-serif;
  }
}

// downgrade modal rollover content

.rolloverInfoContainer {
  display: flex;
  flex-flow: column;
  padding: 16px 0;
  margin-bottom: 16px;
  border-top: 1px solid var(--grey200);

  span {
    color: var(--grey600);
    font-style: italic;
  }

  span:first-of-type {
    margin-bottom: 8px;
  }
}

.confirmButton {
  width: 100%;
  display: block;
  text-align: center;
  cursor: pointer;
  margin-bottom: 16px;
  padding: var(--button-padding);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--fontsize-16);
  box-shadow: var(--button-boxShadow);
  color: var(--button-textColor);
  outline: none;
  font-family: "Inter", sans-serif;
}

.vat {
  margin-left: auto;
  
  span {    
    color: var(--grey500);
  }
}
