@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-flow: column;
  margin-bottom: 24px;
  display: flex;
  flex-flow: column;
  margin-bottom: 24px;
  gap: 20px;

  .alert {
    margin-bottom: 24px;
  }

  .group {
    padding: 24px;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0px 2px 16px rgba(14, 15, 57, 0.1);
    gap: 26px;

    .title {
      margin-bottom: 16px;
      color: $grey-400;
      text-transform: uppercase;
      @include inter--medium-16;
      display: flex;
      align-items: center;

      // span {
      //   margin-left: 4px;
      //   font-weight: 600;
      //   color: $grey-600;
      // }

      // .manageButton {
      //   margin-left: auto;
      //   display: inline-block;
      //   padding: 8px;
      //   background-color: #FFF1ED;
      //   color: var(--brand);
      //   border-radius: 8px;
      //   cursor: pointer;

      //   &:hover {
      //     background-color: rgba(255, 241, 237, 0.75);
      //   }
      // }
    }

    .options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 15px;
    }

    .divider {
      height: 1px;
      background: $grey-200;
      width: 100%;
    }

    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      width: 100%;

      .actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
      
        .flexItemTitle {
          color: $grey-900;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          @include inter--medium-16;
        }
        .smallLink {
          cursor: pointer;
          @include inter--regular-13;
  
          &:hover {
            color: $brand-700;
          }
        }
      }

    }

    .noBottomBorder {
      border-bottom: none;
    }
      
    .planName {
      margin-right: 4px;
      color: $grey-700;
      @include inter--bold-16;
    }

    .paymentProvider {
      margin-right: 4px;
      color: $grey-700;
      @include inter--bold-16;
    }

    .companyName {
      margin-right: 4px;
      color: $grey-700;
      @include inter--bold-16;
    }

    .date {
      margin-right: 4px;
      color: $grey-500;
      @include inter--regular-13;

      @include mobile-breakpoint {
        text-align: right;
      }
    }
    .address {
      margin-right: 4px;
      color: $grey-500;
      @include inter--regular-13;
    }

    .cancelLink {
      width: 100%;
    }

    &:last-of-type {
      border: none;
    }

    .actionButtons {
      display: flex;
      align-items: center;
      margin-left: auto;
      width: 100%;
    }

    .buttonContainer {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-left: auto;
    }

    .tooltip {
      margin-right: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .renewSubscriptionButton {
    padding: var(--button-padding);
    background-color: var(--button-background);
    border-radius: var(--button-radius);
    font-size: var(--fontsize-15);
    box-shadow: var(--button-boxShadow);
    color: var(--button-textColor);
    text-align: center;
    cursor: pointer;
    outline: none;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;

    &:hover {
      opacity: 0.9;
    }
  }

  .secondaryButton {
    padding: var(--button-padding);
    background-color: white;
    border: 1px solid #DE6139;
    border-radius: var(--button-radius);
    font-size: var(--fontsize-15);
    color:var(--button-background);
    text-align: center;
    cursor: pointer;
    outline: none;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;

    &:hover {
      opacity: 0.9;
    }
  }

  .disabledButton {
    cursor: not-allowed;
    background-color: var(--grey300);
    box-shadow: 0 2px 8px var(--grey300);
  }

  .topUpButton {
    // margin-left: 16px;
    width: 100%;
    
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.planNameContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}