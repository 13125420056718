@import "src/styles/v3/variables";

.radio {
  display: flex;
  min-height: 32px;
  align-items: flex-start;

  label {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.radio {
  margin-bottom: 6px;
  display: flex;
}

.radio [type="radio"]:checked,
.radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.radio [type="radio"]:checked + label,
.radio [type="radio"]:not(:checked) + label {
  position: relative;
  padding: 12px;
  padding-left: 44px;
  cursor: pointer;
  display: flex;
  @include inter--medium-14;
  color: $grey-500;
  background-color: $grey-50;
  border: 1px solid $grey-50;
  width: 100%;
  border-radius: 8px;
}

.radio [type="radio"]:checked + label:before,
.radio [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 16px;
  top: 16px;
  width: 18px;
  height: 18px;
  border: 1px solid $grey-300;
  border-radius: 100%;
  background: $white;
}

.radio [type="radio"]:checked + label {
  border: 1px solid $brand-700;
  color: $brand-700;
  background-color: $white;
}

.radio [type="radio"]:checked + label:after,
.radio [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: $brand-700;
  border-color: $brand-700;
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.radioGroup {
  display: flex;
  flex-wrap: wrap;
}

.radioGroup .radio {
  margin-right: 8px;
}

.label {
  position: relative;

  svg {
    margin: auto auto auto 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

.iconz {
  padding-top: 2px;
}