@import "src/styles/v3/variables";

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px;
  gap: 8px;
  border-radius: 8px;
  padding: 12px;

  .contentContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-content: center;
    padding: 0 9%;
  }

  &:disabled {
    background: $grey-100;
    border-style: solid;
    cursor: not-allowed;

    .buttonText {
      @include inter--medium-14;
      color: $grey-400;
    }

    &:hover {
      background: $grey-100;
    }
  }

  .spinner {
    position: absolute;
    left: 13px;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
}

.clickable {
  cursor: pointer;
}

.default {
  background: $brand-700;
  border-color: transparent;

  .buttonText {
    @include inter--medium-14;
    color: $white;
  }

  &:hover {
    background: $brand-500;
  }
}

.outline {
  border: 1px solid $brand-700;
  background: $white;

  .buttonText {
    @include inter--medium-14;
    color: $brand-700;
  }

  &:hover {
    background: $danger-5;
  }
}

.secondary {
  background: $white;
  border: 1px solid $grey-400;

  .buttonText {
    @include inter--medium-14;
    color: $grey-500;
  }

  &:hover {
    background: $grey-50;
  }
}

.tertiary {
  background: $white;
  border: 1px solid transparent;

  .buttonText {
    color: $grey-500;
  }

  &:hover {
    background: $white;
  }
}

.large {
  // padding: 16px 32px;

  .buttonText {
    @include inter--medium-14;
    
    // letter-spacing: 1px;
    // padding-top: 3px;
  }
}

// .medium {
//   padding: 16px 24px;

//   .buttonText {
//     font-family: $maison-neue;
//     font-weight: $font-weight-bold;
//     font-size: 16px;
//     line-height: 16px;
//     letter-spacing: 0.8px;
//   }
// }


.small {
  padding: 16px 24px;

  .buttonText {
    @include inter--medium-13;
    // letter-spacing: 0.6px;
    // padding-top: 3px;
  }
}

.icon {
  width: 48px;
  height: 48px;
}

.arrowButton {
  padding: 0;
}

