@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-flow: column;
  align-items: stretch;
 /* height: 100%;
  padding-top: 30px;
  margin-bottom: 5rem;*/

  /*@include mobile-breakpoint {
   height: auto;
  }*/

  .content {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 24px;
    @include mobile-breakpoint {
      gap: 16px;
    }
    

    .preConfirmationDetails {
      width: 100%;

     /* @include mobile-breakpoint {
        width: 22rem;
      }*/

    }
    .icon {
      height: 72px;
      width: 72px;
    }

    h1 {
      max-width: 400px;
      @include inter--medium-16;
      color: $grey-700;
      line-height: 150%;
      text-align: center;
    }

    .filesDeadlineWarning {
      color: burlywood;
    }

    .confirmButton {
      width: auto;
      height: auto;
      padding: 12px;
      @include mobile-breakpoint {
        width: 100%;
      }
    }

   /* .buttonNoMargin {
      margin-top: 0;
    }*/

    .sessionForm__actionsButtonOutline {
      
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      border-radius: 8px;
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      border: 1px solid var(--grey200);
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
      background: #fff;
      color: var(--grey500);
      cursor: pointer;
      white-space: nowrap;

      // margin-top: 16px;

      &:hover {
        border: 1px solid var(--grey100);
      }
    }

    /*.preConfirmationDetails {
      padding-bottom: 20px;
    }*/

    .twoCreditsMessage {
      display: flex;
      flex-flow: column;
      /*margin-bottom: 24px;*/

      .title {
        font-weight: 600;
        font-size: 14px;
        color: var(--grey500);
        text-align: center;
      }

      ul {
        li {
          font-weight: 400;
          font-size: 14px;
          color: var(--grey500);
          text-align: center;
          list-style-position: inside;
          list-style-type: disc;
        }
      }
    }
  }
}

/* Session Actions */
.confirmation__actions {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px 32px;
  @include mobile-breakpoint {
    padding: 12px 16px;
  }
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /*position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  */
  position: fixed;
  left: 0;
  top: auto;
  right: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.05), 0px 24px 64px rgba(0, 0, 0, 0.05);

}

/*@media (max-width: 576px) {
  .sessionForm__actions {
    padding: 12px 16px;
  }

  .sessionForm__actionsButton {
    margin-left: 160px;
  }
}*/

.sessionForm__actionsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
  border-radius: 8px;
  background-color: var(--grey100);
  font-size: 14px;
  text-align: center;
  color: var(--grey400);
  cursor: not-allowed;
  font-weight: 400;
  box-shadow: none;
  transition: background-color .3s;
}

.sessionForm__saveButton {
  min-width: 120px;
}

@media (max-width: 576px) {
  .sessionForm__actionsButton {
    width: initial;
    padding-left: 16px;
    padding-right: 16px;
    flex-shrink: 0;
  }
}

.sessionFrom__actionsButtonIcon {
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.sessionFrom__actionsButtonIcon svg path {
  stroke: var(--grey200);
}

.sessionForm__actions.isActive .sessionForm__actionsButton {
  cursor: pointer;
  background-color: var(--brand);
  color: var(--white);
  box-shadow: var(--button-boxShadow);
  min-width: 120px;
}

.sessionForm__actions.isActive .sessionForm__actionsButton:hover {
  opacity: 0.9;
}

.sessionForm__actions.isActive .sessionFrom__actionsButtonIcon svg path {
  stroke: var(--white);
}

@media (min-width: 576px) {
  .sessionForm__actionsButtonOutlineCancel {
    display: none !important;
  }
}