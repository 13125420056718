@import "src/styles/v3/variables";

/*
 * FileUpload Container v3
 */

.container {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.fileUploadContainer {
  width: 100%;
} 

.fileUpload {
  color: red;
}

.fileUploadZone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 56px 0;
  border: 1px dashed $grey-300;
  border-radius: 8px;
  background-color: $grey-50;
  color: #bdbdbd;
  outline: none;
  text-align: center;
  transition: border .24s ease-in-out;
  position: relative;
  height: 9vh;

  .spinner {
    transform: scale(1.25);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.uploadHint {
  color: $grey-500;
  @include inter--regular-14;
  text-align: left;
}

.itemsToUpload {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fileUploadZone:hover {
  cursor: pointer;
  background-color: rgba(222, 97, 57, 0.03);
  border-color: $brand-700;
}

.icon {
  display: block;
}

.backgroundText {
  color: $brand-700;
  text-align: center;
  @include inter--medium-14;
}

.tooLargeFileError {
  color: var(--danger);
  font-size: 14px;
  position: relative;
  top: 6px;
}

/* Uploaded Items */

.uploadedZone {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  gap: 8px;
}

.uploadedZone__item {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid var(--grey200);
}

.uploadedZone__itemInfo {
  display: flex;
  align-items: center;
}

.uploadedZone__itemName {
  color: var(--grey600)
}

.uploadedZone__itemIcon {
  margin-right: 8px;
  font-size: 0;
}

.uploadedZone__itemStatus {
  display: flex;
  align-items: center;
}

.uploadedZone__itemStatusIcon {
  display: block;
  width: 24px;
  height: 24px;
  font-size: 0;
  margin-left: 24px;
}

.uploadedZone__itemStatusIcon.isLoading {
  -webkit-animation: rotate 1s normal linear infinite;
  animation: rotate 1s normal linear infinite;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.uploadZone__instructions {
  margin-top: 24px;
}

.uploadZone__instructionsItem {
  display: flex;
  align-items: center;
  color: var(--grey600);
  margin-bottom: 12px;
}

.uploadZone__instructionsItemIcon {
  margin-right: 8px;
}

.uploadedZone__itemDelete {
  color: var(--grey600);
  background-color: transparent;
  cursor: pointer;
  transition: opacity .3s;
  outline: none;

  &:hover {
    color: var(--grey400);
  }
}

/* Note */
.notice {
  display: flex;
  width: 100%;
  margin-top: 16px;
  padding: 16px;
  background-color: #FFFBF2;
  color: #8D6104;
  border-radius: 8px;
  border: 1px solid #FFEFCD;
}

.notice__noteIcon {
  flex-shrink: 0;
  margin-right: 12px;
}

.notice__noteBody {
  text-align: left;
}

.notice__noteTitle {
  margin-bottom: 8px;
  color: #8D6104;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: .3px;
}

.notice__text em {
  font-weight: bold;
}

.notice__text__extra {
  padding-top: 10px;
  font-size: small;
}

.notice__text__extra em {
  font-weight: bold;
}

.uploads {
  margin-top: 16px;
  display: flex;
  flex-flow: column;

  .clearUploadsList {
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    margin-top: 8px;
    color: var(--grey700);
    cursor: pointer;

    &:hover {
      color: var(--grey500);
    }
  }
}

.limitationContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 12px;

  background: #F3F4F6;
  border-radius: 8px;

  .limitationContainerText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */
    
    
    /* Grays/Grey 400 */
    
    color: #9CA3AF;
  }
}