@import "src/styles/v3/variables";

.container {
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 503px;
  gap: 24px;

  @include mobile-breakpoint {
    gap: 32px;
    justify-content: flex-start;
    padding: 0px;
  }

  h1 {
    @include inter--bold-36;
    text-align: center;
    color: $grey-700;
    @include mobile-breakpoint {
      @include inter--bold-24;
    }
  }

  .timezoneSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 100%;

    .timezoneInput{
      width: 100%;
    }
  }

  .timezoneTitle, .personaSurveyTitle {
    @include inter--medium-18;
    color: $grey-700;
  }

  .timezoneSubtitle {
    @include inter--medium-14;
    color: $grey-500;
  }

  .personaSurvey {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 32px;
  }
  .personaOptions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .personaForm {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}