@import "src/styles/v3/variables";

.modal.modal {
  background: #131313;
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  padding: 0px;
}
.container {
  display: flex;
  flex-flow: column;
  gap: 40px;
  max-width: 553px;

  @include mobile-breakpoint {
    padding-top: 60px;
  }
  
  .header {
    display: flex;
    flex-flow: column;

    .title {
      @include inter--bold-36;
      color: $grey-700;
    }
    .subtitle {
      @include inter--regular-16;
      color: $grey-500;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .formInner {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }


    .formTitle {
      @include inter--medium-18;
      color: $grey-700;
    }
  
    .optionsList {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
  
      .option {
        display: contents;
      }
    }
  
    .continue {
      display: flex;
      flex-flow: row;
      justify-content: center;
      gap: 6px;
  
      .link {
        @include inter--medium-14;
        color: $brand-700;
      }
      .message {
        @include inter--medium-14;
        color: $grey-500;
      }
    }
    .addOnDisclaimer {
      @include inter--regular-10;
      color: $grey-500;
    }
  }
}

.trustedBy {
  position: absolute;
  bottom: 10%; 
}

.buttonContent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
