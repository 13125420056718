@import "src/styles/v3/variables";

.modal.modal {
  width: 100%;
  max-width: 640px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 0px;
  position: relative;
  
  @include mobile-breakpoint {
    max-width: none;
    height: 100vh;
    display: flex;
    align-items: center;
  }
}

.modalBlackBG {
  @include mobile-breakpoint {
  background-color: $black;
  }
}

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 32px;
  
  .header {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: 553px;

    .title {
      @include inter--bold-24;
      color: $grey-700;
    }
    .subtitle {
      @include inter--regular-16;
      color: $grey-500;
    }

    .button {
      margin-top: 16px;
      width: 100%;
    }
  }
}
  