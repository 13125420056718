@import "src/styles/v3/variables";

.content {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  /* Grays/Grey 300 */

  border: 1px solid $grey-300;
  border-radius: 8px;

  /* Inside auto layout */

  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.detailsRow {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
  @include mobile-breakpoint {
    flex-direction: column;
  }
  
  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  
  /*@include mobile-breakpoint {
    padding-right: 45px;
    flex-wrap: wrap;
  }*/
}

.separator {
  height: 1px;
  background-color: $grey-300;
  width: 100%;
  /*flex: none;
  align-self: stretch;
  flex-grow: 0;*/
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 6px;
  @include mobile-breakpoint {

    width: 100%;}
  
  
  .oneLineTitle {
    color: $grey-700;
    @include inter--medium-14;
    line-height: 150%;
    text-align: left;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .oneLineDescription {
    @include inter--regular-14;
    line-height: 150%;
    color: $grey-500;
  }

  h3 {
    color: $grey-700;
    @include inter--medium-14;
    line-height: 17px;
    width: 100%;

   /* @include mobile-breakpoint {
      width: 15rem;
    }*/
    /* identical to box height */

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .itemListDescriptionContainer {
    color: $grey-500;
    @include inter--regular-14;
    line-height: 150%;
    /* identical to box height */

    display: flex;
    align-items: center;

    /*.iconBullet {
      margin-top: 4px;
    }*/

    /*@include mobile-breakpoint {
      margin-top:0.5rem;
    }*/
  }
  
  .itemDescriptionContainer {
    color: $grey-500;
    @include inter--regular-14;
    /* identical to box height */

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .icon {
    path {
      stroke: $brand-700;
    }
  }
}
