@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  gap: 32px;
  cursor: pointer;

  @include mobile-breakpoint {
    padding: 16px;
  }

  @include mobile-breakpoint {
    flex-direction: row;
  }

  background: $grey-50;
  border-radius: 8px;

  &:hover {
    background: $grey-100; 
  }

  .copy {
    @include mobile-breakpoint {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .title {
      @include inter--bold-16;
      color: $grey-700;
      @include mobile-breakpoint {
        @include inter--bold-14;
        text-align: left;
      }
    }
    .description {
      @include inter--medium-14;
      color: $grey-600;

      @include mobile-breakpoint {
        @include inter--regular-13;
        text-align: left;
      }
    }
  }
}