@import "src/styles/v3/variables";
.container {
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 503px;
  gap: 48px;

  @include mobile-breakpoint {
    gap: 32px;
    justify-content: flex-start;
    padding: 40px 0px;
  }

  .signupHeader {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 24px;
    width: 100%;
  }

  h1 {
    @include inter--bold-36;
    text-align: center;
    color: $grey-700;
    mix-blend-mode: normal;
    @include mobile-breakpoint {
      @include inter--bold-24;
      gap: 32px;
    }
  }

  .loginParagraph {
    display: flex;
    text-align: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 8px;

    text-align: center;

    .loginMessage {
      @include inter--medium-14;
      color: $grey-500;
    }
    .loginLink {
      @include inter--medium-14;
      text-decoration: underline;
      text-decoration-color: $brand-700;
    }
  }

  .socialLogins {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: nowrap;
    @media (max-width: 899px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  .orText {
    color: $grey-500;
    @include inter--regular-13;
  }

  .divider {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: $grey-500;

    .divLine {
      width: 100%;
      height: 1px;
      background: $grey-300;
    }
  }
  .disclaimer {
    @include inter--regular-10;
    color: $grey-400;
    text-align: center;
    margin-top:-16px;
  }
}

.trustedBy {
  position: absolute;
  bottom: 10%; 
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;


  .formInner {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}