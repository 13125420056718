@import "src/styles/v3/variables";

.modal.modal {
  width: 70vw;
  max-width: 396px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 24px;
  position: relative;

  @include mobile-breakpoint {
    width: 100vw;
    height: 100vh;
    max-width: none;
  }

  .headerImage {
    position: absolute;
    top: -24px;
    left: -24px;
    right: -24px;
    bottom: 0;
    height: 196px;
    background-image: url('../../../../../assets/images/subscription-modal/header.png');
    background-size: cover;

    @include mobile-breakpoint {
      background-image: url('../../../../../assets/images/subscription-modal/header.png');
    }
  }

  .headerSection {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 210px;
  }

  .title {
    @include inter--bold-16;
    color: $grey-700;
  }

  .subtitle {
    @include inter--regular-14;
    color: $grey-600;
  }

  .paymentOptions {
    display: flex;
    align-items: center;
    margin-top: 24px;
    gap: 8px;

    @include mobile-breakpoint {
      flex-wrap: wrap
    }

    .radioOption {
      width: 100%;
    }
  }

  .VATBreakdown {
    margin-top: 24px;
  }

  .payButton {
    width: 100%;
    margin-top: 24px;

    .totalPrice {
      margin: auto;
      margin-top: 6px;
      margin-bottom: 6px;
      font-size: 15px;
      font-weight: 400;

      .crossedOut {
        text-decoration: line-through;
        opacity: 0.55;
      }
    }
  }
}

.countryPickerLabel {
  margin-top: 24px;
  display: flex;

  .label {
    font-size: 16px;
    color: #4B5563;
    font-weight: 00;
  }
}

.modalButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.cancelButton {
  cursor: pointer;
  @include inter--medium-14;
  text-align: center;
  color: $grey-700;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  grid-gap: 4px;
  gap: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  align-content: center;
  justify-content: center;

  @include mobile-breakpoint {
    font-size: 12px;
  }

  a {
    margin-left: 5px;
    cursor: pointer;
  }
  .secureCheckout{
    display: inline-flex;
    align-items: center;
    color: #6B7280;
    margin-top: 24px;

    .lockIcon {
      margin-right: 6px;
    }
  }
}

