@import "src/styles/v3/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0px auto 0 auto;

  .title {
    @include inter--bold-16;
    color: $grey-700;
  }

  .description {
    @include inter--medium-14;
    color: $grey-600;
  }

  .button {
    padding-top: 20px;
  }
}

@media (max-width: 768px) {
  .description {
    padding: 0 16px;
    width: fit-content;
    white-space: break-spaces;
    text-align: center;
    margin: 32px auto 0 auto;
  }
}
