@import "src/styles/v3/variables";

@mixin mobileStyles {
    @include mobile-breakpoint {
        flex-direction: column;
        align-items: stretch;
        width: 100%;
    }
}

.container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;

    @include mobileStyles;
}

.downloadReview {
    display: flex;
    gap: 8px;

    @include mobileStyles;
}
.btns {
    width: 100%;
}
