@import "src/styles/v3/variables";

$vertical-padding: 32px;

/*
 * Navbar Component 
 */

.navbar {
  display: flex;
  align-items: center;
  height: 88px;
  padding: $vertical-padding 24px;
  justify-content: space-between;
  background-color: $grey-900;
  position: sticky;
  z-index: 2000;

  @media only screen and (max-width: $tablet) {
    padding: 16px;
  }

  @include mobile-breakpoint {
    height: 74px;
  }
}

.iconSection {
  display: flex;
  gap: 6px;
  align-items: center;

  @include mobile-breakpoint {
    flex-direction: column;
    @include inter--medium-10;
  }

  path {
    stroke: $white;
  }

}

.navbarLogo {
  display: flex;
  align-items: center;
  width: 40px;

  @include mobile-breakpoint {
    display: none;
  }
}

.navbarNav {
  display: flex;
  gap: 32px;
}

.navbarNavLink {
  display: flex;
  position: relative;
  align-items: center;
  color: $white;
  opacity: .5;
}

.navbarNavLink.isActive {
  opacity: 1;
}

.navbarNavLink.isActive:after {
  content: " ";
  position: absolute;
  bottom: -($vertical-padding);
  height: 4px;
  width: 100%;
  border-radius: 2px 2px 0 0;
  background-color: $brand-700;
  @include mobile-breakpoint {
    height: 3px;
    bottom: -18px;
  }
}

/* Dropdown */
.dropdown__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0;
  // gap: 32px;
}

.getFullAccessButton {
  display: flex;
  // margin-right: 16px;
}

.buttonInDropdownMenu {
  display: flex;
}

.liWithButton {
  padding: 16px;
}

.dropdown__trigger {
  display: flex;
  cursor: pointer;
}

.dropdown__menu {
  position: absolute;
  top: 100px;
  right: 0;
  background: $white;
  border-radius: 8px;
  overflow: hidden;
  min-width: 220px;
  box-shadow: $shadow-gray-large;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.15s ease, transform 0.15s ease, visibility 0.15s;
  z-index: 5000;
}

.withoutCounter {
  top: 80px;
}
.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown__menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown__menu li {
  border-bottom: 1px solid $grey-100;
}

.dropdown__menu li:last-child {
  border-bottom: none;
}

.dropdown__menu li a {
  display: flex;
  align-items: center;
  gap: 8px;
  @include inter--regular-14;
  text-decoration: none;
  padding: 16px;
  cursor: pointer;
  color: $grey-500;
}

.avatarAreaContainer {
  display: flex;
  gap: 32px;
}

.dropdown__menu li a:hover {
  background-color: $grey-50;
}

.dropdown__menuItemIcon {
  display: flex;

  path {
    stroke: $grey-500;
  }
}

// .creditsDisplay {
//   align-items: center;
//   padding: 10px;
//   padding-right: 15px
// }

.counterContainer {
  padding: 16px;
}