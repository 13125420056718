@import "src/styles/v2/variables";

.modal.modal {
  width: 70vw;
  max-width: 456px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 24px;
  position: relative;

  @include mobile-breakpoint {
    width: 100%;
    max-width: none;
  }

  .title {
    text-align: left;
    color: $dark-100;
    border-bottom: 1px solid #D1D5DB;
    padding-bottom: 24px;
    font-weight: 700;
  }

  .container {
    margin-top: 15px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;

    .icon {
      transform: scale(1.4);
      margin-bottom: 14px;
    }

    .dialogTitle {
      font-size: 16px;
      color: #374151; //$dark-700;
      font-weight: 700;
      width: 14rem;
      @include mobile-breakpoint {
        width: 100%;
      }
    }

    .dialogContent {
      font-size: 14px;
      padding-top: 12px;
      color: #6B7280; // $dark-700;
      font-weight: 500;
      width: 22rem;
      @include mobile-breakpoint {
        width: 100%;
      }
    }
  }

  .containerButtons {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 36px;
    text-align: center;
    gap: 24px;
    

    .closeButton {
      display: flex;
      justify-content: center;
      width: 50%;
      border: solid 1px #E5E7EB;
      background: transparent;
      color: #6B7280;
      box-shadow: none;
    }

    .confirmButton {
      display: flex;
      justify-content: center;
      width: 50%;
    }
  }

}
