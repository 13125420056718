@import "src/styles/v2/variables";

.tutorial {
  color: #7F65FF;
  border-color: #7F65FF;
  // secondary: 131313
}
.sales {
  color: #32ACA0;
  border-color: #32ACA0;
  // secondary: #131313
}
.product_updates {
  color: #DE6139;
  border-color: #DE6139;
  // secondary: #131313
}
.roster {
  color: #E0B23B;
  border-color: #E0B23B;
  // secondary: #131313
}
.guest_musician {
  color: #E0B23B;
  border-color: #E0B23B;
  // secondary: #131313
}

.container {
  cursor: pointer;
  background: #111827;
  border-radius: 8px;
  
  &.mediumContainer {
    margin-top: 0;
  }

 @media only screen and (min-width: $mobile) and (max-width: 948px)  {
    // width: calc(948px / 3);
    display: flex;
    align-items: center;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    padding: 32px;
    height: 296px;
    cursor: pointer;

    @include mobile-breakpoint {
      flex-direction: column;
      width: calc(100%);
    }
  }
}

.contentSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  max-width: 60%;

  @include tablet-breakpoint {
  max-width: 100%;
  }

  .contentTop {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  /*@media only screen and (min-width: $mobile) and (max-width: 1366px)  {
    width: 100%;
  }

  @include mobile-breakpoint {
    width: 256px;
  }*/

  &.bigContentSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 60%;

    .contentTop {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &.mediumContentSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 60%;

    .contentTop {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &.smallContentSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:space-between;
    max-width: 100%;

    .contentTop {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  /*@media only screen and (min-width: $mobile) and (max-width: 948px)  {
    width: 100%;
  }*/


  @include mobile-breakpoint {
    width: 100%;
  }


/* @media only screen and (min-width: 1216px) and (max-width: 1366px)  {
     when it changes to 2 cards
    margin-top: 32px;
 }

  @media only screen  and (min-width: $laptop) and (max-width: 1366px)  {
    margin-bottom: 24px;
  }*/

  .category {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
  }
  
  .header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 125%;
    /* or 35px */
  
    display: flex;
    align-items: center;


    /* Grays/White */
  
    color: #FFFFFF;

    /*@include mobile-breakpoint {
      margin-right: 32px;
    }*/

    @media only screen and (max-width: 1366px)  {
      font-size: 28px;
    }
  }
  
  .subheader {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: var(--fontsize-14);
    line-height: 140%;
  
    /* Grays/White */
  
    color: #FFFFFF;
  
    opacity: 0.8;
  }

  .button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: var(--fontsize-14);
    line-height: 150%;
    // width: calc(52%);

    /*@media only screen and (min-width: $mobile) and (max-width: 1366px)  {
      margin-top: 16px;
      margin-bottom: 16px;
    }

     @media only screen and (min-width: $mobile) and (max-width: 948px)  {
       width: 100%;
     }
    @include mobile-breakpoint {
      margin-top: 208px;
      position: absolute;
    }*/

    .buttonText {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: stretch;
      gap: 4px;
      span {
        padding-right: 3px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: nowrap;

      }
      &:has(> .container) {
        color: black;
        justify-content: center;
      }
    }

    .buttonTextWithIcon {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: stretch;
      gap: 4px;
      span {
        padding-right: 3px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: nowrap;

      }
      /*@include mobile-breakpoint {
        margin-right: 32px;
      }*/
    }

    &.centerButton {
      display: flex !important;
      justify-content: center !important;
    }

    &.tutorial {
      color: #7F65FF;
      border: 1px solid #7F65FF;
      // secondary: 131313
      .icon {
        path {
          stroke: #7F65FF;
        }
      }
    }
    &.sales {
      color: #32ACA0;
      border: 1px solid #32ACA0;
      // secondary: #131313
      .icon {
        path {
          stroke: #32ACA0;
        }
      }
    }
    &.product_updates {
      color: #DE6139;
      border: 1px solid #DE6139;
      // secondary: #131313
      .icon {
        path {
          stroke: #DE6139;
        }
      }
    }

    &.roster {
      color: #E0B23B;
      border: 1px solid #E0B23B;
      // secondary: #131313
      .icon {
        path {
          stroke: #E0B23B;
        }
      }
    }

    &.guest_musician {
      color: #E0B23B;
      border: 1px solid #E0B23B;
      // secondary: #131313
      .icon {
        path {
          stroke: #E0B23B;
        }
      }
    }

    &.production {
      color: #E0B23B;
      border: 1px solid #E0B23B;
      // secondary: #131313
      .icon {
        path {
          stroke: #E0B23B;
        }
      }
    }

    &.outline {
      box-shadow: none;
      border-radius: var(--button-radius);
      display: block;
      background-color: transparent;
      text-align: center;
      cursor: pointer;
      outline: none;
      transition: border-color .15s, color .15s;
    }

    &.filled {
      display: flex;
      align-items: center;
      color: #111827;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      /*@media only screen and (min-width: 1366px)  {
        margin-bottom: -3px;
      }

      @media only screen and (max-width: 1366px)  {
        // margin-bottom: -1px;
      }*/

      &.tutorial {
        background-color: #7F65FF;
        border: 1px solid #7F65FF;
        // secondary: 131313
      }
      &.sales {
        background-color: #32ACA0;
        border: 1px solid #32ACA0;
        // secondary: #131313
      }
      &.product_updates {
        background-color: #DE6139;
        border: 1px solid #DE6139;
        // secondary: #131313
      }
      &.roster {
        background-color: #E0B23B;
        border: 1px solid #E0B23B;
        // secondary: #131313
      }
      &.guest_musician {
        background-color: #E0B23B;
        border: 1px solid #E0B23B;
        // secondary: #131313
      }

      .icon {
        path {
          stroke: #111827;
        }
      }
    }
  }
}

.bigContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  background: #111827;
  border-radius: 8px;

  .wrapper {
    display: flex;
    flex-direction: row;
    height: 296px;
    padding: 32px;
    cursor: pointer;

    @include mobile-breakpoint {
      flex-direction: column;
      width: calc(100%);
    }
  }

  .bigHeader {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 125%;
    /* or 35px */
  
    display: flex;
    align-items: center;


    /* Grays/White */
  
    color: #FFFFFF;

    /*@include mobile-breakpoint {
      margin-right: 32px;
    }
*/
    @media only screen and (max-width: 1366px)  {
      font-size: 28px;
    }
  }
  .bigSubheader {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: var(--fontsize-14);
    line-height: 140%;
  
    /* Grays/White */
  
    color: #FFFFFF;
  
    opacity: 0.8;

  }
  /*.bigAnnouncementBtnWrapper {
    margin-top: -10px;
    margin-bottom: 22px;
  }*/
}

.smallContainer {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 0.95 1;
  /*@media only screen and (max-width: 1366px)  {
    display: none;
  }*/

  .wrapper {
    padding: 32px;
    height: 296px;
    cursor: pointer;
  }

  .smallHeader {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 125%;
    display: flex;
    align-items: center;


    /* Grays/White */
  
    color: #FFFFFF;

   /* @include mobile-breakpoint {
      margin-right: 32px;
    }*/

    @media only screen and (max-width: 1366px)  {
      font-size: 28px;
    }
  }

  /*.smallAnnouncementBtnWrapper {
    .outline {
      margin-bottom: 0px;
    }
  }*/
}

.doubleAnnouncementContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  gap: 24px;
}
